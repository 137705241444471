@import '../../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 400px;

  .heading {
    font-family: $boldFont;
    font-size: $fontLarge;
  }

  .label {
    font-family: $boldFont;
    font-size: $fontSizeRegular;
  }
}

.noMargin {
  margin: 0;
}

.input {
  padding: 15px 0 15px 20px;
  border: 1px solid map-get($btnBgColors, borderGrey);
  border-radius: $borderRadius10;
  margin-top: 10px;
  font-size: 1rem;
  width: 100%;
}

.checkboxWithLabelContainer {
  display: flex;
  gap: 4px;
  align-items: center;
}