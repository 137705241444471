@import "../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  .depositInfoTableContainer {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    overflow-x: auto;
    padding-bottom: 80px;

    .tableHeader {
      display: grid;
      grid-template-columns: 12% 12% 20% 20% 12% 12% 60px;
      text-transform: uppercase;
      color: map-get($fontColors, mainBlack);
      font-size: $fontVerySmall;
      font-family: $boldFont;
      letter-spacing: 1.68px;
      background: map-get($bgColors, secondaryWhite);
      padding: 6px 0 6px 0;
    }

    .tableRow {
      display: grid;
      grid-template-columns: 12% 12% 20% 20% 12% 12% 60px;
      color: map-get($fontColors, mainGrey);
      font-size: $fontMedium;
      font-family: $mediumFont;
      text-transform: capitalize;

      .rowDataContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .dataCellRow {
          display: flex;
          justify-content: center;
        }

        .strong {
          font-family: $boldFont;
        }

        .dark {
          color: map-get($fontColors, mainBlack);
        }
      }
    }
  }
}

.label {
  font-family: $boldFont;
  font-size: $fontSizeRegular;
}

.icon {
  width: 20px;
  height: 20px;
}

.deleteIcon {
  width: 20px;
  height: 20px;
  color: map-get($fontColors, mainGrey);

  svg:hover {
    color: map-get($fontColors, errorColor);
    cursor: pointer;
  }
}