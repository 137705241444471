@import '../../styles/variables';

.riskGraphsContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 48px;

  .chartWithTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .chartTitle {
      font-size: $fontBilling;
    }
  }
}
