.modal-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(37, 37, 37, 0.66);
  z-index: 1000;

  .modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: map-get($bgColors, mainWhite);
    box-shadow: $boxShadowBig;
    border-radius: $borderRadius12;
    min-width: 640px;
    min-height: 500px;
    padding-top: 20px;
    padding-bottom: 20px;

    @media (max-width: $breakSm) {
      margin: 0 10px;
      width: unset;
      padding: 20px 5px;
    }

    .modal-close-button {
      position: absolute;
      right: 23px;
      top: 23px;

      .close-icon {
        width: 20px;
        height: 20px;
        color: map-get($fontColors, mainGrey);
        cursor: pointer;
      }

      svg {
        transition-duration: 200ms;
      }

      svg:hover {
        transform: rotate(90deg);
        transition-duration: 200ms;
      }
    }

    .modal-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 45px 130px 65px 130px;
      max-width: 640px;
      min-height: 500px;

      &.users-modal {
        padding: 51px 90px 51px 90px;
        max-width: 780px;

        .main-button {
          max-width: 300px;
        }
      }

      .modal-title {
        font-family: $boldFont;;
        font-size: 25px;
        line-height: 37px;
        color: map-get($dashboardLight, settingsBg);
        text-align: center;
      }

      .modal-description {
        font-family: $regularFont;
        font-size: $fontLarge;;
        line-height: 140%;
        text-align: center;
        color: map-get($fontColors, mainGrey);
      }

      .modal-form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 8px;
        margin-bottom: 30px;

        .input-alert {
          color: map-get($fontColors, errorColor);
          margin-top: 14px;
          margin-right: auto;
        }

        .main-form-input {
          padding: 15px 0 15px 20px;
          border: 1px solid map-get($btnBgColors, borderGrey);
          border-radius: $borderRadius10;
          margin-top: 10px;
          font-size: 1rem;
          width: 100%;
        }
      }
    }
  }
}
